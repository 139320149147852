import { Grid } from "@mui/material";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

import Footer from "./Footer";
import tick_blue from "../Image/tick_blue.png";
import tick_gold from "../Image/tick_gold.png";
import FearAndGreedWidget from "./FearAndGreedWidget_dark";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import InfiniteScroll from "react-infinite-scroll-component";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import graph from "../Image/graph.png";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Sparkline_exchange_chart from "./Sparkline_exchange_chart";
import LazyLoad from "react-lazy-load";

import Navbar from "./Navbar";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Tooltip } from "antd";
import { ProgressBar } from "react-bootstrap";

function Home({ url, Dissconnect, Metamask, WalletC }) {
  // Define state variables
  const [crypt, setCrypt] = useState([]);
  const [crypto_recently, setcrypto_recentlys] = useState([]);
  const [cryptoPrices, setCryptoPrices] = useState({});
  const [marketPrices, setmarketPrices] = useState({});
  const [volume, setVolumes] = useState({});
  const [crypto24hchanges, setCrypto24hchangess] = useState({});

  const [visibleRows, setVisibleRows] = useState(10); // Number of rows initially visible
  const tableRef = useRef(null);
  const [marketCapSortOrder, setMarketCapSortOrder] = useState(true); // Initialize with 'desc' for descending order
  const [priceSortOrder, setPriceSortOrder] = useState(true); // Initialize with 'asc' for ascending order
  const [h24ChangeSortOrder, setH24ChangeSortOrder] = useState(true);
  const [volume24hChangeSortOrder, setvolume24hChangeSortOrder] =
    useState(true);
  const [circulating_supply, setcirculating_supplys] = useState({});

  const [circulatingSupplySortOrder, setCirculatingSupplySortOrder] =
    useState(true);
  const [volumecrypt, setVolumecrypt] = useState([]);
  const [sortCriterion, setSortCriterion] = useState("marketPrices"); // Default sorting criterion

  // Create an IntersectionObserver to load more rows when the last row becomes visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const lastRow = entries[0];
        if (lastRow.isIntersecting) {
          // Load more rows when the last row becomes visible
          setVisibleRows((prevVisibleRows) => prevVisibleRows + 10); // You can adjust the number of rows to load
        }
      },
      { root: null, rootMargin: "0px", threshold: 0.1 },
    );

    if (tableRef.current) {
      observer.observe(tableRef.current);
    }

    return () => {
      if (tableRef.current) {
        observer.unobserve(tableRef.current);
      }
    };
  }, []);

  // Fetch crypto data when the component mounts
  useEffect(() => {
    fetchCryptData();
  }, []);

  // Fetch crypto data from an API
  const fetchCryptData = async () => {
    try {
      const response = await axios.get(`${url}/get/exchange`);

      setcrypto_recentlys(response.data);
      setCrypt(response.data);
      setVolumecrypt(response.data);

      for (let i = 0; i < response.data.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        console.log("35q", response.data[i].project_api_name);
        const requestData = {
          url: `https://pro-api.coinmarketcap.com/v1/exchange/info?slug=${response.data[i].project_api_name}`,
          apiKey: "62751589-8b9c-44d7-b38f-405da98d7b10",
        };

        // Introduce a 5-second delay using setTimeout

        axios
          .post(`${url}/api/fetchData`, requestData)
          .then((response) => {
            console.log(
              "API Response:",
              response.data.data.response.data[i].project_api_name,
            );
          })
          .catch((error) => {
            console.error("API Error:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  // Sort the crypt array by market cap in ascending order

  const getMarketCapDifference = (a, b) => {
    const priceA = marketPrices[a.project_api_name];
    const priceB = marketPrices[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return marketCapSortOrder ? priceB - priceA : priceA - priceB;
  };

  const getPriceDifference = (a, b) => {
    const priceA = cryptoPrices[a.project_api_name];
    const priceB = cryptoPrices[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return priceSortOrder ? priceB - priceA : priceA - priceB;
  };

  const get24hChangeDifference = (a, b) => {
    const priceA = crypto24hchanges[a.project_api_name];
    const priceB = crypto24hchanges[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return h24ChangeSortOrder ? priceB - priceA : priceA - priceB;
  };

  const getvolume24hChangeDifference = (a, b) => {
    const priceA = volume[a.project_api_name];
    const priceB = volume[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return volume24hChangeSortOrder ? priceB - priceA : priceA - priceB;
  };

  const circulate_supplys = (a, b) => {
    const priceA = circulating_supply[a.project_api_name];
    const priceB = circulating_supply[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return circulatingSupplySortOrder ? priceB - priceA : priceA - priceB;
  };

  const sortedCrypt = [...crypt].sort((a, b) => {
    switch (sortCriterion) {
      case "price":
        return getPriceDifference(a, b);
      case "h24Change":
        return get24hChangeDifference(a, b);
      case "volumeh24Change":
        return getvolume24hChangeDifference(a, b);
      case "circulate_supply":
        return circulate_supplys(a, b);
      default:
        return getMarketCapDifference(a, b);
    }
  });

  const togglePriceSort = () => {
    setSortCriterion("price");
  };

  const toggle24hSort = () => {
    setSortCriterion("h24Change");
  };
  const togglevolume24hSort = () => {
    setSortCriterion("volumeh24Change");
  };

  const sortedvolumeCrypt = [...volumecrypt].sort((a, b) =>
    a.market_cap > b.market_cap ? 1 : -1,
  );

  // Fetch crypto prices and related data
  const fetchCryptoPrice = async (projectName, api, parameter) => {
    try {
      const response = await axios.get(api);
      const usd = response.data.trust_score;
      const usd_24h_change = response.data.trade_volume_24h_btc_normalized;
      const usd_24h_vol = response.data.trade_volume_24h_btc;

      // Update state with fetched data
      setCryptoPrices((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd,
      }));
      setCrypto24hchangess((prevPrices) => ({
        ...prevPrices,
        [projectName]: btc_price * usd_24h_change,
      }));

      setVolumes((prevPrices) => ({
        ...prevPrices,
        [projectName]: btc_price * usd_24h_vol,
      }));

      console.log("cryptoPrices", cryptoPrices);
    } catch (error) {
      console.error("Error fetching crypto price:", error);
    }
  };
  sortedvolumeCrypt.sort((a, b) => {
    const priceA = volume[a.project_api_name];
    const priceB = volume[b.project_api_name];
    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return priceB - priceA; // Sort in descending order
  });

  // Fetch crypto prices when sortedCrypt updates
  useEffect(() => {
    sortedCrypt
      .sort(
        (a, b) =>
          marketPrices[b.project_api_name] - marketPrices[a.project_api_name],
      )
      .filter((res) => res.status === "Approved")
      .forEach((res) =>
        fetchCryptoPrice(res.project_api_name, res.api_live_price, "usd"),
      );
    // toggleMarketCapSort();
  }, [sortedCrypt]);

  const [highlight, sethighlight] = useState("light-theme");
  const [base, setbase] = useState("light-theme");
  const [theme, setTheme] = useState("light");

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      sethighlight("#444");
      setbase("#202020");
      setTheme("dark");
    } else {
      sethighlight("#d1d1d1");
      setbase("#efecec");
      setTheme("light");
    }
  }, []);

  const [btc_price, setbtc_prices] = useState();

  useEffect(() => {
    bitcoin_price_fetch();
  }, []);

  const bitcoin_price_fetch = async () => {
    try {
      const response = await axios.get(
        `https://pro-api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd&precision=4&x_cg_pro_api_key=CG-VTWbFPyR2KdGKX9LPTiPFBWW`,
      );
      console.log("response12315");
      setbtc_prices(response.data.bitcoin.usd);
    } catch (error) {}
  };

  return (
    <>
      <SkeletonTheme baseColor={base} highlightColor={highlight}>
        <Navbar
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
        />
        <div className="container-kws">
          <div className="t-a-c f-w-600 f-s-2 m-b-3">Crypto Exchanges</div>

          <div className="m-b-3">
            <Paper
              sx={{
                width: "100%",
                background: "transparent",
              }}
              className="b-s-n"
            >
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="b-c-t c-w"
                >
                  <TableHead className="b-c-t c-p">
                    <TableRow className="b-c-t c-p ">
                      <TableCell align="left" style={{ minWidth: "170" }}>
                        #
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: "170" }}>
                        Exchange
                      </TableCell>
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          togglePriceSort();
                          setPriceSortOrder(!priceSortOrder);
                        }}
                      >
                        Trust Score
                        {priceSortOrder ? " ▲" : " ▼"}
                      </TableCell>

                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          toggle24hSort();
                          setH24ChangeSortOrder(!h24ChangeSortOrder);
                        }}
                      >
                        24h Volume (Normalised)
                        {h24ChangeSortOrder ? " ▲" : " ▼"}
                      </TableCell>

                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          togglevolume24hSort();
                          setvolume24hChangeSortOrder(
                            !volume24hChangeSortOrder,
                          );
                        }}
                      >
                        24h Volume
                        {volume24hChangeSortOrder ? " ▲" : " ▼"}
                      </TableCell>
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        // onClick={() => {
                        //   togglevolume24hSort();
                        //   setvolume24hChangeSortOrder(
                        //     !volume24hChangeSortOrder
                        //   );
                        // }}
                      >
                        Weekly Visits
                        {/* {volume24hChangeSortOrder ? " ▲" : " ▼"} */}
                      </TableCell>
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        // onClick={() => {
                        //   togglevolume24hSort();
                        //   setvolume24hChangeSortOrder(
                        //     !volume24hChangeSortOrder
                        //   );
                        // }}
                      >
                        # Coins
                        {/* {volume24hChangeSortOrder ? " ▲" : " ▼"} */}
                      </TableCell>

                      <TableCell align="right" style={{ minWidth: "170" }}>
                        Last 7 Days
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className="b-c-t">
                    {sortedCrypt.slice(0, visibleRows).map((res, index) => {
                      if (res.status === "Approved") {
                        const priceChange = marketPrices[res.project_api_name];
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            className="c-p"
                          >
                            <TableCell align="left" style={{ minWidth: "170" }}>
                              <a href={`/exchanges/${res.project_api_name}`}>
                                {res.status == "Approved" ? index + 1 : ""}
                              </a>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a href={`/exchanges/${res.project_api_name}`}>
                                <img
                                  src={res.link_logo}
                                  className="w-2_5 h-2_5 b-r-50"
                                />
                                &nbsp; {res.project_name} &nbsp;
                                <span className="g_clr">
                                  {res.project_ticker_symbol}
                                </span>
                                &nbsp; &nbsp;
                                <Tooltip
                                  title={`${
                                    res.tick == "gold"
                                      ? "Sharia Complaint"
                                      : "Verified"
                                  }`}
                                >
                                  <span>
                                    {res.tick ? (
                                      <button className="b-c-t b-n ">
                                        {res.tick == "gold" ? (
                                          <img
                                            src={tick_gold}
                                            className="w-1_5 m-r-2"
                                          />
                                        ) : (
                                          <img
                                            src={tick_blue}
                                            className="w-1_5 m-r-2"
                                          />
                                        )}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </Tooltip>
                              </a>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w t-a-r"
                            >
                              {cryptoPrices[res.project_api_name] !== null ? (
                                <a href={`/exchanges/${res.project_api_name}`}>
                                  {cryptoPrices[res.project_api_name] ? (
                                    <>
                                      <div className="d-f a-i-c j-c-f-e">
                                        <div className="m-r-0_5">
                                          <ProgressBar
                                            variant="success"
                                            now={
                                              cryptoPrices[
                                                res.project_api_name
                                              ] * 10
                                            }
                                            className="w-7"
                                          />
                                        </div>
                                        <div className="">
                                          {" "}
                                          {parseFloat(
                                            cryptoPrices[res.project_api_name],
                                          )
                                            .toFixed(0)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ",",
                                            )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <Skeleton className="w-7" />
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {crypto24hchanges[res.project_api_name] !==
                              null ? (
                                <a href={`/exchanges/${res.project_api_name}`}>
                                  {crypto24hchanges[res.project_api_name] !==
                                  undefined ? (
                                    `$ ${crypto24hchanges[res.project_api_name]
                                      .toFixed(0)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  ) : (
                                    <Skeleton className="w-7" />
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}{" "}
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {volume[res.project_api_name] !== null ? (
                                <a href={`/exchanges/${res.project_api_name}`}>
                                  {volume[res.project_api_name] !==
                                  undefined ? (
                                    `$ ${volume[res.project_api_name]
                                      .toFixed(0)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  ) : (
                                    <Skeleton className="w-7" />
                                  )}{" "}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                            >
                              <a href={`/exchanges/${res.project_api_name}`}>
                                {res.status == "Approved"
                                  ? res.weekly_visits
                                  : ""}
                              </a>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                            >
                              <a href={`/exchanges/${res.project_api_name}`}>
                                {res.status == "Approved" ? res.coin : ""}
                              </a>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {" "}
                              <a href={`/exchanges/${res.project_api_name}`}>
                                <div className="w-7 f-r">
                                  {(
                                    <Sparkline_exchange_chart
                                      api={res.graph_historical_api}
                                    />
                                  ) || <Skeleton className="w-7" />}
                                </div>
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                    {crypt.length > 0 ? (
                      ""
                    ) : (
                      <>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>

                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>

                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>

                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>

                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div ref={tableRef}></div>
            </Paper>
          </div>
        </div>
        <Footer />
      </SkeletonTheme>
      <Toaster position="top-center" reverseOrder={true} />
    </>
  );
}

export default Home;
