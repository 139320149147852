import Web3 from "web3";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import axios from "axios";

let web3 = "https://node1.maalscan.io";
var provider;
var walletprovider;

const walletConnectProv = async () => {
  provider = await EthereumProvider.init({
    projectId: "b9bd638e50d4ce444f33b0499cac8433",
    chains: [786],
    showQrModal: true,
    methods: [
      "eth_sendTransaction",
      "eth_signTransaction",
      "eth_sign",
      "personal_sign",
      "eth_signTypedData",
    ],
    events: ["chainChanged", "accountsChanged"],
  });
  return provider;
};

export const WalletConnect = async () => {
  try {
    provider = await walletConnectProv();
    await provider.disconnect();
    const data = await provider.enable();
    web3 = new Web3(provider);
    walletprovider = provider;
  } catch (error) {
    console.log(error);
  }
};

export const DissconnectWallet = async () => {
  provider = await walletConnectProv();
  await provider.disconnect();
  web3 = null;
};

export const MetaMasklogin = async () => {
  const data = await window.ethereum.enable();
  web3 = new Web3(window.ethereum);
  walletprovider = window.ethereum;
  return data[0];
};

export const getUserAddress = async () => {
  try {
    const address = await web3.eth.getAccounts();
    return address[0];
  } catch (error) {
    // console.log(error)
  }
};

export const towie = async (amount) => {
  try {
    const number = await web3.utils.toWei(amount.toString(), "ether");
    return number;
  } catch (error) {
    // console.log(error)
  }
};

export const fromwie = async (amount) => {
  try {
    const number = await web3.utils.fromWei(amount.toString(), "ether");
    return number;
  } catch (error) {
    // console.log(error)
  }
};

export const GetChainId = async () => {
  try {
    const id = await web3.eth.getChainId();
    return id;
  } catch (error) {}
};

export const userBalanceOfChain = async (userAddress) => {
  try {
    const data = await axios
      .post("https://node1.maalscan.io", {
        jsonrpc: "2.0",
        method: "eth_getBalance",
        params: [userAddress, "latest"],
        id: 1,
      })
      .then(async (res) => {
        const bal = await fromwie(parseInt(res.data.result, 16));
        return Number(bal);
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const convertToLowercase = (str) => {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    let charCode = str.charCodeAt(i);
    if (charCode >= 65 && charCode <= 90) {
      result += String.fromCharCode(charCode + 32);
    } else {
      result += str.charAt(i);
    }
  }
  return result;
};

export const mainprovider = () => {
  return walletprovider;
};
