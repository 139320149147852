import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Methodology({ url, Dissconnect, Metamask, WalletC }) {
  return (
    <div>
      <Navbar Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC} />
      <div className="f-s-2 t-a-c f-w-600 m-b-2">Methodology</div>
      <div className="m-b-2 max-w-50rem d-b m-a">
        RamzMarketCap strives to provide accurate, timely, and unbiased data for
        cryptoassets. Our view is that we should always over-provide data (as
        opposed to censoring and policing information) and let our users draw
        their own conclusions. We actively listen to feedback/requests from our
        users to enrich the quality of the data on our website.
      </div>
      <div className="max-w-50rem d-b m-a ">
        In this connection, please review the following before submitting a
        request:
        <ul>
          <li>
            Listings Criteria:
            https://support.ramzmarketcap.com/hc/en-us/articles/360043659351-Listings-Criteria
          </li>
          <li>
            Methodology (Market Data and Cryptoasset Rank):
            https://support.ramzmarketcap.com/hc/en-us/sections/360008888252-Metric-Methodologies
          </li>
        </ul>
      </div>
      <div className="p-t-2">
        <Footer />
      </div>
    </div>
  );
}

export default Methodology;
