import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import { AiOutlineCopy } from "react-icons/ai";
import { RiArrowGoBackLine } from "react-icons/ri";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);
const notifyMessage = (msg) =>
  toast(msg, {
    duration: 10000,
  });

function Admin({ url, Dissconnect, Metamask, WalletC }) {
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const { name, id } = useParams();

  // console.log("a", id);

  // const [address, setAddress] = useState();
  const [value, setvalues] = useState();
  const [email, setemails] = useState();
  const [subject, setsubjects] = useState();
  const [Review, setReviews] = useState();
  const [Terms, setTermss] = useState();
  const [Declaration, setDeclarations] = useState();
  const [Disclaimer, setDisclaimers] = useState();
  const [relationship_project, setrelationship_projects] = useState();
  const [project_launch_date, setproject_launch_dates] = useState();
  const [project_name, setproject_names] = useState();
  const [project_ticker_symbol, setproject_ticker_symbols] = useState();
  const [cryptoasset, setcryptoassets] = useState();
  const [one_liner_description, setone_liner_descriptions] = useState();
  const [detailed_project_description, setdetailed_project_descriptions] =
    useState();
  const [platform, setplatforms] = useState();
  const [hashing_consensus_algorithm, sethashing_consensus_algorithms] =
    useState();
  const [team_backers_investors, setteam_backers_investorss] = useState();
  const [media_coverage, setmedia_coverages] = useState();
  const [traction_adoption, settraction_adoptions] = useState();
  const [country_origin, setcountry_origins] = useState();
  const [link_logo, setlink_logos] = useState();
  const [website, setwebsites] = useState();
  const [Annex_A, setAnnex_As] = useState();
  const [Emission, setEmissions] = useState();
  const [platform_contract_address, setplatform_contract_addresss] = useState();
  const [contract_address, setcontract_addresss] = useState();
  const [no_of_decimal, setno_of_decimals] = useState();
  const [block_explorer, setblock_explorers] = useState();
  const [source_code, setsource_codes] = useState();
  const [whitepaper, setwhitepapers] = useState();
  const [announcement, setannouncements] = useState();
  const [message_board, setmessage_boards] = useState();
  const [twitter, settwitters] = useState();
  const [reddit, setreddits] = useState();
  const [facebook, setfacebooks] = useState();
  const [project_introduction, setproject_introductions] = useState();
  const [video_channel, setvideo_channels] = useState();
  const [chat, setchats] = useState();
  const [linkedin, setlinkedins] = useState();
  const [mobile_app, setmobile_apps] = useState();
  const [circulating_supply, setcirculating_supplys] = useState();
  const [total_supply, settotal_supplys] = useState();
  const [max_supply, setmax_supplys] = useState();
  const [ico_start_date, setico_start_dates] = useState();
  const [ico_end_date, setico_end_dates] = useState();
  const [ico_launch_price, setico_launch_prices] = useState();
  const [api_live_price, setapi_live_prices] = useState();
  const [graph_historical_api, setgraph_historical_apis] = useState();
  const [cmc_supported_exchange, setcmc_supported_exchanges] = useState();
  const [project_api_name, setproject_api_names] = useState();
  const [attachment, setAttachment] = useState(null);
  const [status, setstatuss] = useState("");

  useEffect(() => {
    PackagesDetails();
  }, []);

  // const [crypt, setcryptos] = useState([]);

  const PackagesDetails = () => {
    try {
      axios.get(`${url}/api/crypto/${name}`).then((res) => {
        console.log("gettingpacakgecarddetails", res.data);
        // setAddress(res.data.address);
        setemails(res.data.email);
        setstatuss(res.data.status);
        setsubjects(res.data.subject);
        setReviews(res.data.Review);
        setproject_api_names(res.data.project_api_name);
        setTermss(res.data.Terms);
        setDeclarations(res.data.Declaration);
        setDisclaimers(res.data.Disclaimer);
        setrelationship_projects(res.data.relationship_project);
        setproject_launch_dates(res.data.project_launch_date);
        setproject_names(res.data.project_name);
        setproject_ticker_symbols(res.data.project_ticker_symbol);
        setcryptoassets(res.data.cryptoasset);
        setone_liner_descriptions(res.data.one_liner_description);
        setdetailed_project_descriptions(res.data.detailed_project_description);
        setplatforms(res.data.platform);
        sethashing_consensus_algorithms(res.data.hashing_consensus_algorithm);
        setteam_backers_investorss(res.data.team_backers_investors);
        setmedia_coverages(res.data.media_coverage);
        settraction_adoptions(res.data.traction_adoption);
        setcountry_origins(res.data.country_origin);
        setlink_logos(res.data.link_logo);
        setwebsites(res.data.website);
        setAnnex_As(res.data.Annex_A);
        setEmissions(res.data.Emission);
        setplatform_contract_addresss(res.data.platform_contract_address);
        setcontract_addresss(res.data.contract_address);
        setno_of_decimals(res.data.no_of_decimal);
        setblock_explorers(res.data.block_explorer);
        setsource_codes(res.data.source_code);
        setwhitepapers(res.data.whitepaper);
        setannouncements(res.data.announcement);
        setmessage_boards(res.data.message_board);
        settwitters(res.data.twitter);
        setreddits(res.data.reddit);
        setfacebooks(res.data.facebook);
        setproject_introductions(res.data.project_introduction);
        setvideo_channels(res.data.video_channel);
        setchats(res.data.chat);
        setlinkedins(res.data.linkedin);
        setmobile_apps(res.data.mobile_app);
        setcirculating_supplys(res.data.circulating_supply);
        settotal_supplys(res.data.total_supply);
        setmax_supplys(res.data.max_supply);
        setico_start_dates(res.data.ico_start_date);
        setico_end_dates(res.data.ico_end_date);
        setico_launch_prices(res.data.ico_launch_price);
        setapi_live_prices(res.data.api_live_price);
        setgraph_historical_apis(res.data.graph_historical_api);
        setcmc_supported_exchanges(res.data.cmc_supported_exchange);
        setAttachment(res.data.attachment);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [remark, setremarks] = useState([
    "Initial remark 1",
    "Initial remark 2",
  ]);

  const address = JSON.parse(localStorage.getItem("address"));

  console.log("url", url, id, address);

  const getting_remark = () => {
    try {
      axios.get(`${url}/api/remarks/${id}/${address}`).then((res) => {
        // console.log("cdfdsfda", res.data);
        setremarks(res.data);
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("Endpoint not found:", error.response.data);
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  // getting_remark();

  const copyTextToClipboard = (text) => {
    notify("copied");
    return navigator.clipboard.writeText(text);
  };

  return (
    <div>
      <Navbar Dissconnect={Dissconnect} Metamask={Metamask} WalletC={WalletC} />
      <div className="container-kws">
        <div className="section">
          <div className="d-f a-i-c j-c-s-b m-b-4">
            <div className="">
              <a href="/request_list">
                <RiArrowGoBackLine className="c-p " />
              </a>
            </div>
            <div className="f-s-2 f-w-600">Request List Detail</div>
            <div className=""></div>
          </div>

          <div className="bg_violet b-r-15 pa-2">
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Status</div>
              <div className="t-a-r">{status ? status : "Pending"}</div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">User id</div>
              <div className="t-a-r">
                {address ? address.slice(0, 5) : "No data to show"}{" "}
                <AiOutlineCopy
                  onClick={() => copyTextToClipboard(address)}
                  className="c-p"
                />
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Request id</div>
              <div className="t-a-r">{id ? id : "No data to show"}</div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Email</div>
              <div className="t-a-r">{email ? email : "No data to show"}</div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Subject</div>
              <div className="t-a-r">
                {subject ? subject : "No data to show"}
              </div>
            </div>
            <hr />

            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Relationship with the Project</div>
              <div className="t-a-r">
                {relationship_project
                  ? relationship_project
                  : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Project Launch Date</div>
              <div className="t-a-r">
                {project_launch_date ? project_launch_date : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Project Name</div>
              <div className="t-a-r">
                {project_name ? project_name : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Project Ticker/Symbol (Do not put a $ sign if there is none in
                the ticker)
              </div>
              <div className="t-a-r">
                {project_ticker_symbol
                  ? project_ticker_symbol
                  : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Cryptoasset Tags - Sector/Categories</div>
              <div className="t-a-r">
                {cryptoasset ? cryptoasset : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">One-liner description</div>
              <div className="t-a-r">
                {one_liner_description
                  ? one_liner_description
                  : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Detailed Project Description (Cryptoasset)
              </div>
              <div className="t-a-r">
                {detailed_project_description
                  ? detailed_project_description
                  : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Platform (e.g. EOS, NEO, Ethereum, proprietary blockchain).
              </div>
              <div className="t-a-r">
                {platform ? platform : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Team/Backers/Investors</div>
              <div className="t-a-r">
                {team_backers_investors
                  ? team_backers_investors
                  : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Country of Origin (where majority of team is located)
              </div>
              <div className="t-a-r">
                {country_origin ? country_origin : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Link to Logo</div>
              <div className="t-a-r">
                {link_logo ? link_logo.slice(0, 5) : "No data to show"}{" "}
                <AiOutlineCopy onClick={() => copyTextToClipboard(link_logo)} />
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Website 1</div>
              <div className="t-a-r">
                {website ? website : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Annex A - Rich List & Reserve Addresses (Mandatory for
                Circulating Supply/Ranking)(optional)
              </div>
              <div className="t-a-r">
                {Annex_A ? Annex_A : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Emission/release schedule(optional)</div>
              <div className="t-a-r">
                {Emission ? Emission : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Platform of Contract Address 1</div>
              <div className="t-a-r">
                {platform_contract_address
                  ? platform_contract_address
                  : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Contract Address 1</div>
              <div className="t-a-r">
                {contract_address ? contract_address : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Number of Decimals (Contract Address 1)(optional)
              </div>
              <div className="t-a-r">
                {no_of_decimal ? no_of_decimal : "No data to show"}
              </div>
            </div>
            <hr />
            {/* <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Block Explorer 1(optional)</div>
              <div className="t-a-r">{block_explorer}</div>
            </div><hr/> */}
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Source Code (e.g. Github, Gitter)(optional)
              </div>
              <div className="t-a-r">
                {source_code ? source_code : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Whitepaper / Technical Documentation(optional)
              </div>
              <div className="t-a-r">
                {whitepaper ? whitepaper : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Announcement (e.g. Bitcointalk)(optional)
              </div>
              <div className="t-a-r">
                {announcement ? announcement : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Message Board 1 (e.g. Blog/Medium)(optional)
              </div>
              <div className="t-a-r">
                {message_board ? message_board : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Twitter</div>
              <div className="t-a-r">
                {twitter ? twitter : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Reddit (optional)</div>
              <div className="t-a-r">{reddit ? reddit : "No data to show"}</div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Facebook(optional)</div>
              <div className="t-a-r">
                {facebook ? facebook : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">Project introduction video(optional)</div>
              <div className="t-a-r">
                {project_introduction
                  ? project_introduction
                  : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Video Channel (e.g. YouTube)(optional)
              </div>
              <div className="t-a-r">
                {video_channel ? video_channel : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Chat 1 (e.g. Discord, Telegram, Slack, Weibo).
              </div>
              <div className="t-a-r">{chat ? chat : "No data to show"}</div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">LinkedIn(optional)</div>
              <div className="t-a-r">
                {linkedin ? linkedin : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Mobile App 1 (e.g. Google Play store, iTunes store)(optional)
              </div>
              <div className="t-a-r">
                {mobile_app ? mobile_app : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Circulating Supply (# of coins that are circulating in the
                market and in the general public's hands.)
              </div>
              <div className="t-a-r">
                {circulating_supply ? circulating_supply : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Total Supply (# of coins in existence right now minus any coins
                that have been verifiably burned.)(optional)
              </div>
              <div className="t-a-r">
                {total_supply ? total_supply : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">
                Max Supply (maximum # of coins that will ever exist in the
                lifetime of the asset)
              </div>
              <div className="t-a-r">
                {max_supply ? max_supply : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">ICO/IEO Start Date(optional)</div>
              <div className="t-a-r">
                {ico_start_date ? ico_start_date : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">ICO/IEO End Date(optional)</div>
              <div className="t-a-r">
                {ico_end_date ? ico_end_date : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">ICO/IEO/Launch Price(optional)</div>
              <div className="t-a-r">
                {ico_launch_price ? ico_launch_price : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">*** API LIVE PRICE </div>
              <div className="t-a-r w-b-b-a">
                {api_live_price ? api_live_price : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">*** GRAPH HISTORICAL API </div>
              <div className="t-a-r w-b-b-a">
                {graph_historical_api
                  ? graph_historical_api
                  : "No data to show"}
              </div>
            </div>
            <hr />
            <div className="d-f j-c-s-b a-i-c m-y-2">
              <div className="m-r-2">List of RMC exchanges</div>
              <div className="t-a-r">
                {cmc_supported_exchange
                  ? cmc_supported_exchange
                  : "No data to show"}
              </div>
            </div>
          </div>

          <div className="">
            <Link to={`/update_list_detail/${project_api_name}/${id}`}>
              <button className="submit b-n bg_b_clr b-c-t c-w p-x-2 p-y-0_5 b-r-5 m-t-2">
                Update
              </button>
            </Link>
          </div>
          <div className="m-b-2 f-s-1_5 f-w-600 t-a-c m-t-1">Remark</div>
          <div className="bg_violet b-r-15 pa-2 m-b-4">
            {" "}
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                background: "transparent",
              }}
            >
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className="f-w-600">
                      <TableCell align="center" style={{ minWidth: "170" }}>
                        S.No.
                      </TableCell>
                      <TableCell align="center" style={{ minWidth: "170" }}>
                        Remark
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {remark.map((res, index) => {
                      return (
                        <>
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {res.remark ? res.remark : "No data to show"}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
                {remark.length > 0 ? (
                  ""
                ) : (
                  <div className="p-y-5 t-a-c c-w f-w-600">
                    No data to show.....
                  </div>
                )}
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        show={show2}
        onHide={handleClose2}
        className="b-r-15 b_clr_blue  b-1"
      >
        <Modal.Body>
          <div className="pa-1">
            <div className="f-s-1_5 f-w-600 t-a-c m-b-2">Remark </div>
            <div className="m-b-2">
              <textarea
                className="b-c-t w-100 b-r-1 b-1 b_clr_blue pa-0_5 "
                placeholder=""
                rows={5}
              />
            </div>
            <div className="t-a-r">
              <button
                className="b-c-t b-n c-w t-a-c p-x-3 p-y-0_5 b-r-15 d-b m-a  bg_b_clr "
                onClick={handleClose2}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Toaster position="top-center" reverseOrder={true} />
    </div>
  );
}

export default Admin;
