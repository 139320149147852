import React, { useEffect, useLayoutEffect, useState } from "react";
import ramz from "../Image/ramz.png";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import ramz_dark from "../Image/logo_dark.png";
import ramz_light from "../Image/ramz.png";
import { Modal } from "react-bootstrap";
import meta from "../Image/meta.png";
import google from "../Image/google.png";
import walletconnect from "../Svg/walletconnect.svg";
import { RxCross2 } from "react-icons/rx";
import { gapi } from "gapi-script";

import { GoogleLogin } from "react-google-login";

const clientId =
  "203596689282-eurli5p4jdsocp5p41cshc986nr0q91d.apps.googleusercontent.com";

function Footer({ Dissconnect, Metamask, WalletC }) {
  const addr = JSON.parse(localStorage.getItem("address"));
  const [reroute, setreroute] = useState(false);

  const notify = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);
  const notifyMessage = (msg) =>
    toast(msg, {
      duration: 10000,
    });

  // ---------dark---------mode----------------

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  }, []);
  const [theme, setTheme] = useState("light-theme");
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  // const Reroute = () => {
  //   window.location.href = "/requests/new";
  // };

  // const Reroute = () => {
  //   if (addr && reroute) {
  //     window.location.href = "/requests/new";
  //   }
  // };

  // console.log("adawe", addr, reroute);

  // Call the function with a delay of 5 seconds
  // setTimeout(Reroute, 500);

  const onSuccess = (res) => {
    console.log("ss", res);
    window.localStorage.setItem("address", JSON.stringify(res.profileObj));
    window.location.href = "/requests/new";
    setreroute(true);
  };
  const onFailure = (res) => {
    console.log("ff", res);
  };
  const customGoogleButton = ({ onClick }) => (
    <div onClick={onClick}>
      <img
        src={google}
        className="w-3_5" // Replace with the URL of your custom image
      />
    </div>
  );

  return (
    <div className="section">
      <div className="container-kws">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-5 m-y-1 f-w-600">
            {theme == "dark-theme" ? (
              <>
                {" "}
                <Link to="/">
                  <div className=" m-r-3">
                    <img src={ramz_light} className="w-13 " />
                  </div>
                </Link>
              </>
            ) : (
              <>
                {" "}
                <Link to="/">
                  <div className=" m-r-3">
                    <img src={ramz_dark} className="w-13 " />
                  </div>
                </Link>
              </>
            )}
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg col-xl m-y-1">
            <ul className="l-s-t-n">
              <li className="f-w-600 m-b-1 ">Products</li>
              <a href="https://maalscan.io/" target="_blank">
                {" "}
                <a href="https://maalscan.io/" target="_blank">
                  <li className="m-y-0_5">Blockchain Explorer</li>
                </a>
              </a>

              <a href="/sitemap">
                {" "}
                <li className="m-y-0_5">Sitemap</li>
              </a>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg col-xl m-y-1">
            <ul className="l-s-t-n">
              <li className="f-w-600 m-b-1 ">Sites</li>

              <a href="/terms">
                {" "}
                <li className="m-y-0_5">Terms of use</li>
              </a>
              <a href="/privacy">
                {" "}
                <li className="m-y-0_5">Privacy Policy</li>
              </a>

              <a href="/disclaimer">
                {" "}
                <li className="m-y-0_5">Disclaimer</li>
              </a>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg col-xl m-y-1">
            <ul className="l-s-t-n">
              <li className="f-w-600 m-b-1 ">Support</li>

              {addr ? (
                <a href="/requests/new">
                  <li className="f-w-600 m-b-0_5 c-p">Listing Form</li>
                </a>
              ) : (
                <li className="f-w-600 m-b-0_5 c-p" onClick={handleShow2}>
                  Listing Form
                </li>
              )}
              <li className="m-y-0_5">Contact Support</li>
            </ul>
          </div>
        </div>
        <div className="m-t-2"> © 2023 RamzMarketCap. All rights reserved</div>
        <div className="m-t-2 v-h">
          {" "}
          Designed by{" "}
          <a href="https://kesaviwebsolutions.com" target="_blank">
            {" "}
            KESAVI WEB SOLUTIONS{" "}
          </a>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={true} />
      <Modal show={show2} onHide={handleClose2} className="">
        <div className="">
          <div className="d-f a-i-c j-c-s-b">
            <div className="f-s-1_25 f-w-600">RamzMarketCap Login</div>
            <div className="">
              <RxCross2
                className="f-w-600 f-s-1_25 c-p"
                onClick={() => handleClose2()}
              />
            </div>
          </div>
          <div className="walletconnect d-f a-i-c j-c-s-e m-t-4 m-b-2">
            <span
              className="c-p"
              onClick={() => {
                handleClose2();
              }}
            >
              <GoogleLogin
                clientId={clientId}
                buttonText=""
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={"single_host_origin"}
                // isSignedIn={true}
                render={customGoogleButton}
              />
            </span>

            <img
              src={meta}
              alt=""
              className="w-7 c-p"
              onClick={() => {
                Metamask();
                handleClose2();
                setreroute(true);
              }}
            />
            <img
              src={walletconnect}
              alt=""
              className="w-4 c-p"
              onClick={() => {
                WalletC();
                handleClose2();
                setreroute(true);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Footer;
