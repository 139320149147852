import React, { memo } from "react";
import Safe from "react-safe";

function Disqus() {
  return (
    <div>
      <div className="transparent  pa-2">
        <div id="disqus_thread"></div>
      </div>

      <Safe.script>
        {(function () {
          // DON'T EDIT BELOW THIS LINE
          var d = document,
            s = d.createElement("script");
          s.src = "https://bif-fund.disqus.com/embed.js";
          s.setAttribute("data-timestamp", +new Date());
          (d.head || d.body).appendChild(s);
        })()}
      </Safe.script>
    </div>
  );
}

export default memo(Disqus); // Wrap your component with memo
