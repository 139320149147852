import React, { useEffect, useState } from "react";
import axios from "axios";
import Super_admin_navbar from "./Super_admin_navbar";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";

function Super_admin({ url }) {
  const [email, setemails] = useState("");
  const [password, setpasswords] = useState("");
  const [otp_box, setotp_boxs] = useState(false);
  const [token, setToken] = useState("");
  const [protectedData, setProtectedData] = useState("");
  const [otp, setotps] = useState("");
  const notify = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  const [randomNumber, setRandomNumber] = useState(null);

  const generateRandomNumber = () => {
    const newRandomNumber = Math.floor(100000 + Math.random() * 900000); // Generate a random 6-digit number
    setRandomNumber(newRandomNumber);
  };

  useEffect(() => {
    generateRandomNumber();
  }, [email]);

  // const sendOtp = async () => {
  //   await generateRandomNumber();
  //   await sendEmail();
  // };

  // const api_key = useStoreState((state) => state.email_api_key);

  const sendEmail = async () => {
    const apiKey =
      "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";
    const endpoint = "https://api.sendinblue.com/v3/smtp/email";

    try {
      const response = await axios.post(
        endpoint,
        {
          sender: { email: "sender@gmail.com" },
          to: [{ email: `${email}` }],
          subject: "otp",
          textContent: `${randomNumber}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": apiKey,
          },
        },
      );

      if (response.status === 201) {
        console.log("abc");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const otp_check = () => {
    if (randomNumber === otp) {
      window.location.href = "/ResetPassword";
      console.log("cor");
    }
    console.log("otp");
  };

  return (
    <div>
      <div className="container-kws"></div>{" "}
    </div>
  );
}

export default Super_admin;
