import React, { useEffect, useLayoutEffect, useState } from "react";

const FearAndGreedWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://static.coinstats.app/widgets/v2/fear-and-greed.js";

    script.onload = () => {
      try {
        const existingWidget = document.querySelector("fear-and-greed");
        if (!existingWidget) {
          const fearAndGreedWidget = document.createElement("fear-and-greed");
          fearAndGreedWidget.setAttribute("theme", "light");
          fearAndGreedWidget.setAttribute("direction", "horizontal");
          fearAndGreedWidget.setAttribute("background", "transparent");
          fearAndGreedWidget.setAttribute("boxShadow", "none");
          fearAndGreedWidget.setAttribute("max-width", "none");
          // Set background to transparent
          // Add more attributes here as needed

          const widgetContainer = document.getElementById(
            "fearAndGreedContainer",
          );
          widgetContainer.appendChild(fearAndGreedWidget);
        }
      } catch (error) {
        console.error("Error initializing the widget:", error);
      }
    };

    script.onerror = (error) => {
      console.error("Error loading the script:", error);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      id="fearAndGreedContainer"
      className="widget-container"
      style={{ width: "100%", height: "100%", boxShadow: "none" }}
    >
      {/* The widget will be inserted here */}
    </div>
  );
};

export default FearAndGreedWidget;
