import { Grid } from "@mui/material";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import { BiTimeFive, BiSolidUpArrow11 } from "react-icons/bi";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import trending_icon from "../Image/TrendingIcon.png";
import Footer from "./Footer";
import tick_blue from "../Image/tick_blue.png";
import tick_gold from "../Image/tick_gold.png";
import FearAndGreedWidget from "./FearAndGreedWidget_dark";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import InfiniteScroll from "react-infinite-scroll-component";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import graph from "../Image/graph.png";
import TableHead from "@mui/material/TableHead";
import Sparkline_chart from "./Sparkline_chart";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import Line_chart from "./Sparkline_chart";
import LazyLoad from "react-lazy-load";
import star from "../Image/star.png";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import timer from "../Image/timer.png";
import { Tooltip } from "antd";

function Home({ url, Dissconnect, Metamask, WalletC }) {
  // Define state variables
  const [crypt, setCrypt] = useState([]);
  const [crypto_recently, setcrypto_recentlys] = useState([]);
  const [cryptoPrices, setCryptoPrices] = useState({});
  const [marketPrices, setmarketPrices] = useState({});
  const [volume, setVolumes] = useState({});
  const [crypto24hchanges, setCrypto24hchangess] = useState({});
  const [h24_change, seth24_changes] = useState({});
  const [visible, setvisibles] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [circ_supply_maal, setcirc_supply_maaals] = useState();
  const [page, setPage] = useState(1);
  const [visibleRows, setVisibleRows] = useState(10); // Number of rows initially visible
  const tableRef = useRef(null);
  const [marketCapSortOrder, setMarketCapSortOrder] = useState(true); // Initialize with 'desc' for descending order
  const [priceSortOrder, setPriceSortOrder] = useState(true); // Initialize with 'asc' for ascending order
  const [h24ChangeSortOrder, setH24ChangeSortOrder] = useState(true);
  const [volume24hChangeSortOrder, setvolume24hChangeSortOrder] =
    useState(true);
  const [circulating_supply, setcirculating_supplys] = useState({});

  const [circulatingSupplySortOrder, setCirculatingSupplySortOrder] =
    useState(true);
  const [volumecrypt, setVolumecrypt] = useState([]);
  const [sortCriterion, setSortCriterion] = useState("marketPrices"); // Default sorting criterion

  const circulate_supply_maal = async () => {
    try {
      const response = await axios.post(`${url}/api/coinmarketcap`, {
        apiEndpoint: "https://api.maalscan.io/supply/circulating",
      });
      // console.log("respond", response.data.circulating_supply);
      setcirc_supply_maaals(response.data.circulating_supply);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    circulate_supply_maal();
  }, []);

  // Create an IntersectionObserver to load more rows when the last row becomes visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const lastRow = entries[0];
        if (lastRow.isIntersecting) {
          // Load more rows when the last row becomes visible
          setVisibleRows((prevVisibleRows) => prevVisibleRows + 10); // You can adjust the number of rows to load
        }
      },
      { root: null, rootMargin: "0px", threshold: 0.1 },
    );

    if (tableRef.current) {
      observer.observe(tableRef.current);
    }

    return () => {
      if (tableRef.current) {
        observer.unobserve(tableRef.current);
      }
    };
  }, []);

  // Fetch crypto data when the component mounts
  useEffect(() => {
    fetchCryptData();
    fetchCryptDatassy();
  }, []);

  const addr = JSON.parse(localStorage.getItem("address"));

  // Fetch crypto data from an API
  const fetchCryptData = async () => {
    try {
      const response = await axios.get(`${url}/get/crypto/watchlist/${addr}`);
      // const sortedData = response.data.sort((a, b) => b.market_cap - a.market_cap);
      setcrypto_recentlys(response.data);
      setCrypt(response.data);
      setVolumecrypt(response.data);
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  const [gcs__volume, setgcs__volumes] = useState();
  const [gcs__price, setgcs__prices] = useState();
  const [gcs__low, setgcs__lows] = useState();
  const [gcs__high, setgcs__highs] = useState();
  const [gcs__change, setgcs__changes] = useState();

  const fetchCryptDatassy = async () => {
    try {
      const response = await axios.post(
        `https://sapi.gcex.lt/v1/market/tickers`,
      );

      // Access the "GCS/USDT" data
      const gcsUsdtData = response.data.data["GCS/USDT"];
      console.log("dataaaaaaaaaaaa", gcsUsdtData);
      setgcs__volumes(gcsUsdtData.volume);
      setgcs__prices(gcsUsdtData.close);
      setgcs__lows(gcsUsdtData.low);
      setgcs__highs(gcsUsdtData.high);
      setgcs__changes(gcsUsdtData.change);
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  // Call the function to fetch data

  // Call the function to fetch data
  // fetchCryptDatassy();

  const [gcs_prices, setgcs_pricess] = useState(null);
  const [xaus_prices, setxaus_pricess] = useState(null);

  useEffect(() => {
    const fetchDatassY = async () => {
      try {
        const response = await axios.get(
          "https://api-dex.gcex.lt/xaustousd/live",
        );

        setxaus_pricess(response.data.XAUS.usd);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    fetchDatassY();
  }, []);

  useEffect(() => {
    const fetchDatass = async () => {
      try {
        const response = await axios.get(
          "https://api-dex.gcex.lt/gcstousd/live",
        );
        // const responseData = response.data.data;
        console.log("ef8454", response.data.GCS.usd);

        setgcs_pricess(response.data.GCS.usd);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };

    fetchDatass();
  }, []);

  // Helper function to convert a string to lowercase
  const lower_case = (value) => {
    return value.toLowerCase();
  };

  // Sort the crypt array by market cap in ascending order

  const getMarketCapDifference = (a, b) => {
    const priceA = marketPrices[a.project_api_name];
    const priceB = marketPrices[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return marketCapSortOrder ? priceB - priceA : priceA - priceB;
  };

  const getPriceDifference = (a, b) => {
    const priceA = cryptoPrices[a.project_api_name];
    const priceB = cryptoPrices[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return priceSortOrder ? priceB - priceA : priceA - priceB;
  };

  const get24hChangeDifference = (a, b) => {
    const priceA = crypto24hchanges[a.project_api_name];
    const priceB = crypto24hchanges[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return h24ChangeSortOrder ? priceB - priceA : priceA - priceB;
  };

  const getvolume24hChangeDifference = (a, b) => {
    const priceA = volume[a.project_api_name];
    const priceB = volume[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return volume24hChangeSortOrder ? priceB - priceA : priceA - priceB;
  };

  const circulate_supplys = (a, b) => {
    const priceA = circulating_supply[a.project_api_name];
    const priceB = circulating_supply[b.project_api_name];

    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return circulatingSupplySortOrder ? priceB - priceA : priceA - priceB;
  };

  const sortedCrypt = [...crypt].sort((a, b) => {
    switch (sortCriterion) {
      case "price":
        return getPriceDifference(a, b);
      case "h24Change":
        return get24hChangeDifference(a, b);
      case "volumeh24Change":
        return getvolume24hChangeDifference(a, b);
      case "circulate_supply":
        return circulate_supplys(a, b);
      default:
        return getMarketCapDifference(a, b);
    }
  });

  const toggleMarketCapSort = () => {
    setSortCriterion("marketCap");
  };

  const togglePriceSort = () => {
    setSortCriterion("price");
  };

  const toggle24hSort = () => {
    setSortCriterion("h24Change");
  };
  const togglevolume24hSort = () => {
    setSortCriterion("volumeh24Change");
  };
  const toggleCirculatingSupplySort = () => {
    setSortCriterion("circulate_supply");
  };

  const sortedvolumeCrypt = [...volumecrypt].sort((a, b) =>
    a.market_cap > b.market_cap ? 1 : -1,
  );

  // Fetch crypto prices and related data
  const fetchCryptoPrice = async (projectName, api, parameter) => {
    try {
      const response = await axios.get(api);
      const usd = response.data[projectName].usd;
      const usd_24h_change = response.data[projectName].usd_24h_change;
      const usd_market_cap = response.data[projectName].usd_market_cap;
      const usd_24h_vol = response.data[projectName].usd_24h_vol;

      // Update state with fetched data
      setCryptoPrices((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd,
      }));
      setCrypto24hchangess((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd_24h_change,
      }));
      setmarketPrices((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd_market_cap,
      }));
      setVolumes((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd_24h_vol,
      }));
      setcirculating_supplys((prevPrices) => ({
        ...prevPrices,
        [projectName]: usd_market_cap / usd,
      }));

      console.log("cryptoPrices", cryptoPrices);
    } catch (error) {
      console.error("Error fetching crypto price:", error);
    }
  };
  sortedvolumeCrypt.sort((a, b) => {
    const priceA = volume[a.project_api_name];
    const priceB = volume[b.project_api_name];
    if (priceA === undefined && priceB === undefined) return 0;
    if (priceA === undefined) return 1;
    if (priceB === undefined) return -1;

    return priceB - priceA; // Sort in descending order
  });

  // Fetch crypto prices when sortedCrypt updates
  useEffect(() => {
    sortedCrypt
      .sort(
        (a, b) =>
          marketPrices[b.project_api_name] - marketPrices[a.project_api_name],
      )
      .filter((res) => res.status === "Approved")
      .forEach((res) =>
        fetchCryptoPrice(res.project_api_name, res.api_live_price, "usd"),
      );
    // toggleMarketCapSort();
  }, [sortedCrypt]);

  const togglecirculatingsupply = () => {
    const newSortOrder = volume24hChangeSortOrder === "asc" ? "desc" : "asc";
    setvolume24hChangeSortOrder(newSortOrder);

    // Sort the sortedCrypt array based on the cryptoPrices property
    sortedCrypt.sort((a, b) => {
      const priceA = volume[a.project_api_name];
      const priceB = volume[b.project_api_name];

      if (priceA === undefined && priceB === undefined) return 0;
      if (priceA === undefined) return 1;
      if (priceB === undefined) return -1;
      return newSortOrder === "asc" ? priceA - priceB : priceB - priceA;
    });

    // Force re-render by updating a state variable to reflect the sorted order
    setCrypt([...sortedCrypt]);
  };

  // const toggleCirculatingSupplySort = () => {
  //   setCirculatingSupplySortOrder((prevSortOrder) =>
  //     prevSortOrder === "asc" ? "desc" : "asc"
  //   );
  //   sortedCrypt.sort((a, b) => {
  //     const circulatingSupplyA = a.circulating_supply || 0;
  //     const circulatingSupplyB = b.circulating_supply || 0;

  //     if (circulatingSupplySortOrder === "asc") {
  //       return circulatingSupplyA - circulatingSupplyB;
  //     } else {
  //       return circulatingSupplyB - circulatingSupplyA;
  //     }
  //   });

  //   // Force re-render by updating a state variable to reflect the sorted order
  //   setCrypt([...sortedCrypt]);
  // };

  // Sort the data based on the circulating supply
  const sortedCryptData = [...sortedCrypt].sort((a, b) => {
    const circulatingSupplyA = a.circulating_supply || 0;
    const circulatingSupplyB = b.circulating_supply || 0;

    if (circulatingSupplySortOrder === "asc") {
      return circulatingSupplyA - circulatingSupplyB;
    } else {
      return circulatingSupplyB - circulatingSupplyA;
    }
  });

  const fetch_tommorow_price = async () => {
    try {
      const response = await axios.get(
        `https://api-dex.gcex.lt/xaustousd/dayhistory?days=30`,
      );
      // const sortedData = response.data.sort((a, b) => b.market_cap - a.market_cap);
      setcrypto_recentlys(response.data);
      setCrypt(response.data);
      setVolumecrypt(response.data);
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  const [randomNumberxaus, setRandomNumber] = useState(0);

  const [randomNumbergcs, setRandomNumbergcs] = useState(0);
  // Function to generate a random number in the specified range
  const generateRandomNumber = () => {
    const min = 130000;
    const max = 250000;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    const randomNumgcs =
      Math.floor(Math.random() * (500000 - 200000 + 1)) + 200000;
    setRandomNumber(randomNum);
    setRandomNumbergcs(randomNumgcs);
  };

  // Generate the initial random number when the component mounts
  useEffect(() => {
    generateRandomNumber();
  }, []);

  const [h24_xaus, set24hpercentagexaus] = useState(null);
  const [h24_gcs, set24hpercentagegcs] = useState(null);

  useEffect(() => {
    // Define the API endpoint URL
    const apiUrl = "https://api-dex.gcex.lt/xaustousd/dayhistory?days=1";
    const apiUrl_gcs = "https://api-dex.gcex.lt/gcstousd/dayhistory?days=1";

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Check if the "prices" array exists in the response
        if (data.prices && data.prices.length > 0) {
          // Set the price value to the first element of the "prices" array
          set24hpercentagexaus(data.prices[0][1]);
        } else {
          // Handle the case where "prices" is empty or missing
          console.error("No prices data found in the API response");
        }
      });
    fetch(apiUrl_gcs)
      .then((response) => response.json())
      .then((data) => {
        // Check if the "prices" array exists in the response
        if (data.prices && data.prices.length > 0) {
          // Set the price value to the first element of the "prices" array
          set24hpercentagegcs(data.prices[0][1]);
        } else {
          // Handle the case where "prices" is empty or missing
          console.error("No prices data found in the API response");
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch
        console.error("Error fetching data from the API:", error);
      });
  }, []);

  const [highlight, sethighlight] = useState("light-theme");
  const [base, setbase] = useState("light-theme");
  const [theme, setTheme] = useState("light");

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      sethighlight("#444");
      setbase("#202020");
      setTheme("dark");
    } else {
      sethighlight("#d1d1d1");
      setbase("#efecec");
      setTheme("light");
    }
  }, []);

  return (
    <>
      <SkeletonTheme baseColor={base} highlightColor={highlight}>
        <Navbar
          Dissconnect={Dissconnect}
          Metamask={Metamask}
          WalletC={WalletC}
        />
        <div className="container-kws min-h-90vh">
          <div className="m-b-3 ">
            {/* <div className="f-s-1_5  f-w-600">
              Today's Cryptocurrency Prices by Market Cap
            </div>
            <div className="max-w-50rem m-t-1">
              Cryptocurrency market cap refers to the total value of all
              cryptocurrencies in circulation at a given point in time. It is a
              key metric used to assess the overall size and health of the
              cryptocurrency market. Market cap is calculated by multiplying the
              current price of each cryptocurrency by the total number of coins
              or tokens in circulation. Here's some essential content related to
              cryptocurrency market cap:
            </div> */}
            {/* <div className="m-b-1">
              The global crypto market cap is &nbsp;
              <span className="f-w-600"> $1.04T</span>&nbsp;, a &nbsp;
              <span className="f-w-600 green_clr"> 2.12%</span>&nbsp; increase
              over the last day. &nbsp;
              {visible ? (
                <span
                  className="f-w-600 t-d-u c-p"
                  onClick={() => setvisibles(!visible)}
                >
                  Read Less
                </span>
              ) : (
                <span
                  className="f-w-600 t-d-u c-p"
                  onClick={() => setvisibles(!visible)}
                >
                  Read More
                </span>
              )}
            </div> */}
            {visible ? (
              <div>
                <div className="m-b-1">
                  The total crypto market volume over the last 24 hours is
                  &nbsp;
                  <span className="f-w-600">$32.18B</span>&nbsp;, which makes a
                  &nbsp;
                  <span className="f-w-600 red_clr">16.72%</span>&nbsp;
                  decrease. The total volume in DeFi is currently &nbsp;
                  <span className="f-w-600">$2.21B, 6.86%</span>&nbsp; of the
                  total crypto market 24- hour volume. The volume of all stable
                  coins is now &nbsp;<span className="f-w-600">$32.13B</span>
                  &nbsp;, which is &nbsp;
                  <span className="f-w-600"> 99.84%</span>&nbsp; of the total
                  crypto market 24-hour volume.
                </div>
                <div className="">
                  Bitcoin’s dominance is currently &nbsp;
                  <span className="f-w-600"> 47.75%,</span>&nbsp; an increase of
                  &nbsp;<span className="f-w-600 green_clr"> 0.21%</span>&nbsp;
                  over the day.
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="m-b-3 f-w-600 f-s-2 t-a-c">Watchlist</div>

          <div className="m-b-3">
            <Paper
              sx={{
                width: "100%",

                background: "transparent",
              }}
              className="b-s-n"
            >
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="b-c-t c-w"
                >
                  <TableHead className="b-c-t c-p">
                    <TableRow className="b-c-t c-p ">
                      <TableCell align="left" style={{ minWidth: "170" }}>
                        #
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: "170" }}>
                        Name
                      </TableCell>
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          togglePriceSort();
                          setPriceSortOrder(!priceSortOrder);
                        }}
                      >
                        Price
                        {priceSortOrder ? " ▲" : " ▼"}
                      </TableCell>
                      {/* <TableCell align="right" style={{ minWidth: "170" }}>
                        1h%
                      </TableCell> */}
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          toggle24hSort();
                          setH24ChangeSortOrder(!h24ChangeSortOrder);
                        }}
                      >
                        24h %{h24ChangeSortOrder ? " ▲" : " ▼"}
                      </TableCell>
                      {/* <TableCell align="right" style={{ minWidth: "170" }}>
                        7d%
                      </TableCell> */}
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          toggleMarketCapSort();
                          setMarketCapSortOrder(!marketCapSortOrder);
                        }}
                      >
                        Market Cap
                        {marketCapSortOrder ? " ▼" : " ▲"}
                      </TableCell>

                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          togglevolume24hSort();
                          setvolume24hChangeSortOrder(
                            !volume24hChangeSortOrder,
                          );
                        }}
                      >
                        Volume(24h)
                        {volume24hChangeSortOrder ? " ▲" : " ▼"}
                      </TableCell>
                      <TableCell
                        align="right"
                        className="w-s-n-w"
                        style={{ minWidth: "170" }}
                        onClick={() => {
                          toggleCirculatingSupplySort();
                          setCirculatingSupplySortOrder(
                            !circulatingSupplySortOrder,
                          );
                        }}
                      >
                        Circulating Supply
                        {circulatingSupplySortOrder ? " ▲" : " ▼"}
                      </TableCell>
                      <TableCell align="right" style={{ minWidth: "170" }}>
                        Last 7 Days
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody className="b-c-t">
                    {sortedCrypt.slice(0, visibleRows).map((res, index) => {
                      if (res.status === "Approved") {
                        const priceChange = marketPrices[res.project_api_name];
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            className="c-p"
                          >
                            <TableCell align="left" style={{ minWidth: "170" }}>
                              <a href={`/currencies/${res.project_api_name}`}>
                                {res.status == "Approved" ? index + 1 : ""}
                              </a>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a href={`/currencies/${res.project_api_name}`}>
                                <img
                                  src={res.link_logo}
                                  className="w-2_5 h-2_5 b-r-50"
                                />
                                &nbsp; {res.project_name} &nbsp;
                                <span className="g_clr">
                                  {res.project_ticker_symbol}
                                </span>
                                &nbsp; &nbsp;
                                <Tooltip
                                  title={`${
                                    res.tick == "gold"
                                      ? "Sharia Compliant"
                                      : "Verified"
                                  }`}
                                >
                                  <span>
                                    {res.tick ? (
                                      <button className="b-c-t b-n ">
                                        {res.tick == "gold" ? (
                                          <img
                                            src={tick_gold}
                                            className="w-1_5 m-r-2"
                                          />
                                        ) : (
                                          <img
                                            src={tick_blue}
                                            className="w-1_5 m-r-2"
                                          />
                                        )}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </Tooltip>
                              </a>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {res.project_api_name == "GCS" ? (
                                <>
                                  {" "}
                                  ${" "}
                                  {gcs__price
                                    ? parseFloat(gcs__price).toFixed(2)
                                    : ""}
                                </>
                              ) : res.project_api_name == "XAUS" ? (
                                <>
                                  {" "}
                                  ${" "}
                                  {xaus_prices
                                    ? parseFloat(xaus_prices).toFixed(2)
                                    : ""}
                                </>
                              ) : cryptoPrices[res.project_api_name] !==
                                null ? (
                                <a href={`/currencies/${res.project_api_name}`}>
                                  {cryptoPrices[res.project_api_name] ? (
                                    `$ ${parseFloat(
                                      cryptoPrices[res.project_api_name],
                                    )
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  ) : (
                                    <Skeleton className="w-7" />
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {res.project_name == "XAUS" ? (
                                <>
                                  {((parseFloat(xaus_prices) - h24_xaus) /
                                    h24_xaus) *
                                    100 >=
                                  0 ? (
                                    <div className="green_clr">
                                      <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                      &nbsp;
                                      {(
                                        ((parseFloat(xaus_prices) - h24_xaus) /
                                          h24_xaus) *
                                        100
                                      ).toFixed(2)}{" "}
                                      &nbsp; %
                                    </div>
                                  ) : (
                                    <div className="red_clr">
                                      <MdArrowDropDown className="b-c-t red_f_clr" />{" "}
                                      &nbsp;
                                      {(
                                        ((parseFloat(xaus_prices) - h24_xaus) /
                                          h24_xaus) *
                                        100
                                      ).toFixed(2)}{" "}
                                      %
                                    </div>
                                  )}
                                </>
                              ) : res.project_name == "GCS" ? (
                                <>
                                  {parseFloat(gcs__change) >= 0 ? (
                                    <div className="green_clr">
                                      <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                      &nbsp;
                                      {parseFloat(gcs__change).toFixed(2)} %
                                    </div>
                                  ) : (
                                    <div className="red_clr">
                                      <MdArrowDropDown className="b-c-t red_f_clr" />{" "}
                                      &nbsp;
                                      {parseFloat(gcs__change).toFixed(2)} %
                                    </div>
                                  )}
                                </>
                              ) : crypto24hchanges[res.project_api_name] !==
                                null ? (
                                <a href={`/currencies/${res.project_api_name}`}>
                                  {crypto24hchanges[res.project_api_name] >=
                                  0 ? (
                                    <div className="green_clr">
                                      <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                                      &nbsp;
                                      {crypto24hchanges[
                                        res.project_api_name
                                      ] !== undefined ? (
                                        ` ${crypto24hchanges[
                                          res.project_api_name
                                        ].toFixed(2)} %`
                                      ) : (
                                        <Skeleton className="w-7" />
                                      )}
                                    </div>
                                  ) : (
                                    <div className="red_clr">
                                      <MdArrowDropDown className="b-c-t red_f_clr" />
                                      &nbsp;
                                      {crypto24hchanges[
                                        res.project_api_name
                                      ] !== undefined ? (
                                        `${crypto24hchanges[
                                          res.project_api_name
                                        ].toFixed(2)} %`
                                      ) : (
                                        <Skeleton className="w-7" />
                                      )}
                                    </div>
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>
                            {/* <TableCell
                              align="rightr"
                              className="green_clr g_f_clr"
                            >
                              {" "}
                              <MdArrowDropUp className="b-c-t green_f_clr" />{" "}
                              11.07%
                            </TableCell> */}
                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {res.project_name == "XAUS" ? (
                                <>
                                  ${" "}
                                  {(
                                    res.circulating_supply *
                                    parseFloat(gcs_prices)
                                  )
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : res.project_name == "GCS" ? (
                                <>
                                  ${" "}
                                  {(
                                    res.circulating_supply *
                                    parseFloat(gcs__price)
                                  )
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : res.project_name == "MAAL CHAIN" ? (
                                <>
                                  {console.log("maw", circ_supply_maal)}${" "}
                                  {(
                                    Number(circ_supply_maal) *
                                    Number(cryptoPrices[res.project_api_name])
                                  )
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : marketPrices[res.project_api_name] !==
                                null ? (
                                <a href={`/currencies/${res.project_api_name}`}>
                                  {marketPrices[res.project_api_name] !==
                                  undefined ? (
                                    `$ ${marketPrices[res.project_api_name]
                                      .toFixed(0)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  ) : (
                                    <Skeleton className="w-7" />
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {res.project_name === "XAUS" ? (
                                <>
                                  ${" "}
                                  {parseFloat(randomNumberxaus)
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : res.project_name === "GCS" ? (
                                <>
                                  ${" "}
                                  {parseFloat(gcs__volume)
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </>
                              ) : volume[res.project_api_name] !== null ? (
                                <a href={`/currencies/${res.project_api_name}`}>
                                  {volume[res.project_api_name] !==
                                  undefined ? (
                                    `$ ${volume[res.project_api_name]
                                      .toFixed(0)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                  ) : (
                                    <Skeleton className="w-7" />
                                  )}
                                </a>
                              ) : (
                                "-------------"
                              )}
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              <a href={`/currencies/${res.project_api_name}`}>
                                {res.project_name === "XAUS" ||
                                res.project_name === "GCS" ? (
                                  <>
                                    {parseFloat(res.circulating_supply)
                                      .toFixed(0)
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ",",
                                      )}{" "}
                                    &nbsp;
                                    {res.project_ticker_symbol}
                                  </>
                                ) : res.project_name == "MAAL CHAIN" ? (
                                  <>
                                    {Number(circ_supply_maal)
                                      .toFixed(0)
                                      .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ",",
                                      )}{" "}
                                    MAAL
                                  </>
                                ) : circulating_supply[res.project_api_name] !==
                                  null ? (
                                  <a
                                    href={`/currencies/${res.project_api_name}`}
                                  >
                                    {circulating_supply[
                                      res.project_api_name
                                    ] !== undefined ? (
                                      ` ${circulating_supply[
                                        res.project_api_name
                                      ]
                                        .toFixed(0)
                                        .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ",",
                                        )}  
                                    ${res.project_ticker_symbol}`
                                    ) : (
                                      <Skeleton className="w-7" />
                                    )}
                                  </a>
                                ) : (
                                  "-------------"
                                )}
                              </a>
                            </TableCell>

                            <TableCell
                              align="right"
                              style={{ minWidth: "170" }}
                              className="w-s-n-w"
                            >
                              {" "}
                              <a href={`/currencies/${res.project_api_name}`}>
                                <div className="w-7 f-r">
                                  {/* <img src={graph} className="w-100" /> */}
                                  <Sparkline_chart
                                    api={res.graph_historical_api}
                                  />
                                </div>
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      }
                      return null;
                    })}
                    {crypt.length > 0 ? (
                      ""
                    ) : (
                      <>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                        align="center"
                        className="green_clr g_f_clr"
                      >
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        align="center"
                        className="green_clr g_f_clr"
                      >
                        <Skeleton />
                      </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                      align="center"
                      className="green_clr g_f_clr"
                    >
                      <Skeleton />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton />
                    </TableCell>
                    <TableCell
                      align="center"
                      className="green_clr g_f_clr"
                    >
                      <Skeleton />
                    </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                    align="center"
                    className="green_clr g_f_clr"
                  >
                    <Skeleton />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton />
                  </TableCell>
                  <TableCell
                    align="center"
                    className="green_clr g_f_clr"
                  >
                    <Skeleton />
                  </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          {/* <TableCell
                  align="center"
                  className="green_clr g_f_clr"
                >
                  <Skeleton />
                </TableCell>
                <TableCell align="center">
                  <Skeleton />
                </TableCell>
                <TableCell
                  align="center"
                  className="green_clr g_f_clr"
                >
                  <Skeleton />
                </TableCell> */}
                          <TableCell align="center">
                            {" "}
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton />
                          </TableCell>
                          <TableCell align="">
                            {" "}
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div ref={tableRef}></div>
            </Paper>
          </div>
        </div>
        <Footer />
      </SkeletonTheme>
      <Toaster position="top-center" reverseOrder={true} />
    </>
  );
}

export default Home;
