import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Super_admin_navbar from "./Super_admin_navbar";
import { AiOutlineCopy } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);
const notifyMessage = (msg) =>
  toast(msg, {
    duration: 10000,
  });

function Admin({ url }) {
  useEffect(() => {
    PackagesDetails();
  }, []);

  const [crypt, setcryptos] = useState([]);

  const PackagesDetails = () => {
    try {
      axios.get(`${url}/get/crypto`).then((res) => {
        console.log("gettingpacakgecarddetails", res.data);
        setcryptos(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log("url", url);

  const copyTextToClipboard = (text) => {
    notify("copied");
    return navigator.clipboard.writeText(text);
  };

  return (
    <div>
      <Super_admin_navbar />
      <div className="container-kws">
        <div className="section">
          <div className="t-a-c f-s-2 f-w-600  m-b-4">Admin Panel</div>
          <div className="">
            {" "}
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                background: "transparent",
              }}
            >
              <TableContainer sx={{ maxHeight: 700 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className="f-w-600 bg_d_b_clr">
                      <TableCell
                        align="center"
                        style={{ minWidth: "170" }}
                        className="bg_d_b_clr"
                      >
                        User Id
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "170" }}
                        className="bg_d_b_clr"
                      >
                        Request Id
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "170" }}
                        className="bg_d_b_clr"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "170" }}
                        className="bg_d_b_clr"
                      >
                        Subject
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "170" }}
                        className="bg_d_b_clr"
                      >
                        Project Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "170" }}
                        className="bg_d_b_clr"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ minWidth: "170" }}
                        className="bg_d_b_clr"
                      >
                        View More
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {crypt &&
                      crypt.map((res, index) => {
                        return (
                          <>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                              <TableCell align="center">
                                {res.address.slice(25)} &nbsp;
                                <AiOutlineCopy
                                  onClick={() =>
                                    copyTextToClipboard(res.address)
                                  }
                                />
                              </TableCell>
                              <TableCell align="center">
                                {res._id.slice(15)}{" "}
                                <AiOutlineCopy
                                  onClick={() => copyTextToClipboard(res._id)}
                                />
                              </TableCell>
                              <TableCell align="center">{res.email}</TableCell>
                              <TableCell align="center">
                                {res.subject}
                              </TableCell>
                              <TableCell align="center">
                                {res.project_name}
                              </TableCell>
                              <TableCell align="center">
                                {res.status ? res.status : "Pending"}
                              </TableCell>
                              <TableCell align="center">
                                <Link
                                  to={`/admin_detail/${res.project_api_name}/${res._id}`}
                                >
                                  <button className="bg_b_clr b-n b-r-5 pa-0_5 c-w">
                                    View More
                                  </button>
                                </Link>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
                {crypt.length > 0 ? (
                  ""
                ) : (
                  <div className="p-y-5 t-a-c c-w f-w-600">
                    No data to show.....
                  </div>
                )}
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
      <Footer />
      <Toaster position="top-center" reverseOrder={true} />
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </div>
  );
}

export default Admin;
