import React, { useEffect, useLayoutEffect, useState } from "react";

import { ImCross } from "react-icons/im";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import crypto from "../Image/crypto.png";
import tick_gold from "../Image/tick_golds.png";
import stars from "../Image/star.png";
import profiles from "../Image/profile.png";
import home from "../Image/home.png";
import submit_request from "../Image/submit_request.png";
import list from "../Image/list.png";
import logouts from "../Image/logout.png";
import Logout from "./Logout";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineLogout,
  AiOutlineMenu,
  AiOutlineProfile,
  AiOutlineSearch,
} from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { BiGasPump } from "react-icons/bi";
import ice_pie from "../Image/ice_pie.png";
import { HiOutlineDocumentRemove } from "react-icons/hi";
import Modal from "react-bootstrap/Modal";
import meta from "../Image/meta.png";
import google from "../Image/google.png";
import walletconnect from "../Svg/walletconnect.svg";
import light from "../Image/light.png";
import night from "../Image/moon.png";
import exchanges from "../Image/exchanges.png";

import "animate.css";

import { CgProfile } from "react-icons/cg";

import logo from "../Image/nfe-logo.png";
import { Link } from "react-router-dom";
import ramz_dark from "../Image/logo_dark.png";
import ramz_light from "../Image/ramz.png";
import { BsFillSunFill, BsMoonFill } from "react-icons/bs";

import { gapi } from "gapi-script";

import { GoogleLogin } from "react-google-login";

function Navbar({ Dissconnect, Metamask, WalletC }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  //Watchlist

  const addr = JSON.parse(localStorage.getItem("address"));

  // ---------dark---------mode----------------

  useLayoutEffect(() => {
    const data = window.localStorage.getItem("theme");
    if (data === "dark-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  }, []);

  const [theme, setTheme] = useState("light-theme");
  const toggleThemelight = () => {
    theme === "dark-theme" ? setTheme("light-theme") : setTheme("light-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "light-theme" : "light-theme",
    );
    window.location.reload();
  };
  const toggleThemedark = () => {
    theme === "dark-theme" ? setTheme("dark-theme") : setTheme("dark-theme");
    window.localStorage.setItem(
      "theme",
      theme === "dark-theme" ? "dark-theme" : "dark-theme",
    );
    window.location.reload();
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const address = localStorage.getItem("address");

  const clientId =
    "203596689282-eurli5p4jdsocp5p41cshc986nr0q91d.apps.googleusercontent.com";

  // useEffect(() => {
  //   function start() {
  //     gapi.client.init({
  //       clientid: clientId,
  //       scope: "",
  //     });
  //   }
  //   gapi.load("client:auth2", start);
  // });

  // var userDataString = localStorage.getItem("userData");

  // if (userDataString) {
  //   var userData = JSON.parse(userDataString);
  //   var imageUrl = userData.imageUrl;
  //   console.log(imageUrl);
  // } else {
  //   // console.error("UserData not found in localStorage");
  // }

  const onSuccess = (res) => {
    console.log("ss", res);
    window.localStorage.setItem("address", JSON.stringify(res.profileObj));
  };
  const onFailure = (res) => {
    console.log("ff", res);
  };
  const customGoogleButton = ({ onClick }) => (
    <div onClick={onClick}>
      <img
        src={google}
        className="w-3_5" // Replace with the URL of your custom image
      />
    </div>
  );
  return (
    <>
      <div className="container-kws  ">
        <div className=" bg_clr">
          <div className="d-f j-c-s-b a-i-c p-y-1">
            <div className="d-f a-i-c">
              {theme == "dark-theme" ? (
                <>
                  {" "}
                  <Link to="/">
                    <div className=" m-r-3">
                      <img src={ramz_light} className="w-13 " />
                    </div>
                  </Link>
                </>
              ) : (
                <>
                  {" "}
                  <Link to="/">
                    <div className=" m-r-3">
                      <img src={ramz_dark} className="w-13 " />
                    </div>
                  </Link>
                </>
              )}

              <Link to="/">
                <div className="m-r-2 m-q-b-d-n">
                  <img src={crypto} className="w-1_75" />
                  &nbsp;Cryptocurrencies
                </div>
              </Link>

              <a href="/shariacoin">
                <div className="m-r-2 m-q-b-d-n">
                  {" "}
                  <img src={tick_gold} className="w-1_75" />
                  &nbsp;Sharia Coins
                </div>
              </a>

              <a href="/exchanges">
                <div className="m-q-b-d-n">
                  {" "}
                  <img src={exchanges} className="w-1_75" />
                  &nbsp;Exchanges
                </div>
              </a>
            </div>
            <div className="d-f a-i-c ">
              <div className="m-l-1_5 c-pq  f-s-1 f-w-600 c-p">
                {theme === "light-theme" ? (
                  <BsMoonFill
                    onClick={() => {
                      toggleThemedark();
                    }}
                  />
                ) : (
                  <BsFillSunFill
                    onClick={() => {
                      toggleThemelight();
                    }}
                  />
                )}
              </div>

              <div className="m-l-2">
                {address ? (
                  <div className="d-f a-i-c c-w ">
                    <div className="m-r-1">
                      {" "}
                      <a href={`/watchlist/${addr}`}>
                        {" "}
                        <div className="d-f a-i-c m-q-b-d-n">
                          <img src={stars} className="w-1_25" />
                          &nbsp;Watchlist
                        </div>
                      </a>
                    </div>
                    <div className="">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="b-c-t b-n c-i"
                        >
                          <img src={profiles} className="w-2_5" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="">
                          <Link to="/">
                            <Dropdown.Item
                              className="p-r-4 d-f a-i-c m-y-0_5"
                              href="/"
                            >
                              <img src={home} className="w-1_5" />
                              &nbsp;Home
                            </Dropdown.Item>
                          </Link>
                          <a href="/requests/new">
                            <Dropdown.Item
                              className="p-r-4 d-f a-i-c m-y-0_5"
                              href="/requests/new"
                            >
                              <img src={submit_request} className="w-1_5" />
                              &nbsp;Submit request
                            </Dropdown.Item>
                          </a>
                          <Link to="/request_list">
                            <Dropdown.Item
                              className="p-r-4 d-f a-i-c m-y-0_5"
                              href="/request_list"
                            >
                              <img src={list} className="w-1_5" />
                              &nbsp;List of request
                            </Dropdown.Item>
                          </Link>

                          {/* // <Dropdown.Item className="p-r-4 d-f a-i-c m-y-0_5">
                            //   <Logout />
                            // </Dropdown.Item> */}

                          <Dropdown.Item
                            className="p-r-4 d-f a-i-c m-y-0_5"
                            onClick={Dissconnect}
                          >
                            <img src={logouts} className="w-1_5" />
                            &nbsp;Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ) : (
                  <button
                    className="login clr  b-1 b-c-t b-r-5 p-x-2 p-y-0_5"
                    onClick={() => handleShow2()}
                  >
                    Login
                  </button>
                )}
              </div>
              <div className="m-q-a-d-n m-l-2">
                <AiOutlineMenu onClick={handleShow} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="ma-0 m-b-3" />

      <Offcanvas show={show} onHide={handleClose} className="pa-1">
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className="image-dog1">
              <a href="/" target="_blank">
                <div className="f-s-1_5 ">
                  {" "}
                  {theme == "dark-theme" ? (
                    <>
                      {" "}
                      <Link to="/">
                        <div className=" ">
                          <img src={ramz_light} className="w-13 " />
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Link to="/">
                        <div className=" ">
                          <img src={ramz_dark} className="w-13 " />
                        </div>
                      </Link>
                    </>
                  )}
                </div>
              </a>
            </div>
          </Offcanvas.Title>
          <ImCross onClick={handleClose} color="black" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="">
            <Link to="/">
              <div className="m-t-1">
                {" "}
                <img src={crypto} className="w-2" />
                &nbsp; Cryptocurrencies
              </div>
            </Link>
            <a href="/shariacoin">
              <div className="m-t-1">
                {" "}
                <img src={tick_gold} className="w-1_75" />
                &nbsp;Sharia Coins
              </div>
            </a>
            <a href="/exchanges">
              <div className="m-t-1">
                {" "}
                <img src={exchanges} className="w-1_75" />
                &nbsp;Exchanges
              </div>
            </a>
            <div className="m-t-1">
              {addr ? (
                <a href={`/watchlist/${addr}`}>
                  {" "}
                  <div className=" c-w">
                    <img src={stars} className="w-1_25" /> Watchlist
                  </div>
                </a>
              ) : (
                <div className=" c-w"></div>
              )}{" "}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={show2} onHide={handleClose2} className="">
        <div className="">
          <div className="d-f a-i-c j-c-s-b">
            <div className="f-s-1_25 f-w-600">RamzMarketCap Login</div>
            <div className="">
              <RxCross2
                className="f-w-600 f-s-1_25 c-p"
                onClick={() => handleClose2()}
              />
            </div>
          </div>
          <div className="walletconnect d-f a-i-c j-c-s-e m-t-4 m-b-2">
            <span
              className="c-p"
              onClick={() => {
                handleClose2();
              }}
            >
              <GoogleLogin
                clientId={clientId}
                buttonText=""
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={"single_host_origin"}
                // isSignedIn={true}
                render={customGoogleButton}
              />
            </span>

            <img
              src={meta}
              alt=""
              className="w-7 c-p"
              onClick={() => {
                Metamask();
                handleClose2();
              }}
            />
            <img
              src={walletconnect}
              alt=""
              className="w-4 c-p"
              onClick={() => {
                WalletC();
                handleClose2();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Navbar;
