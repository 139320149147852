import React, { useEffect, useState } from "react";
import axios from "axios";
import Super_admin_navbar from "./Super_admin_navbar";

import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";

const notify = (msg) => toast.success(msg);
const notifyError = (msg) => toast.error(msg);
const notifyMessage = (msg) =>
  toast(msg, {
    duration: 10000,
  });

function Super_admin({ url }) {
  const [name, setnames] = useState("");
  const [email, setemails] = useState("");
  const [username, setusernames] = useState("");
  const [confirm_password, setconfirm_passwords] = useState("");
  const [password, setpasswords] = useState("");
  const [token, setToken] = useState("");
  const [protectedData, setProtectedData] = useState("");

  const { id } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(`${url}/admin/${id}`, {
        name,
        email,
        username,
        password,
      });

      console.log("Updated admin:", response.data);
      notify("Update Successful");
      // Handle success or update state accordingly
    } catch (error) {
      console.error("Error updating admin:", error.response.data.message);
      // Handle error or update state accordingly
    }
  };
  return (
    <div>
      {" "}
      <Super_admin_navbar />
      <form onSubmit={handleSubmit}>
        <div className="container-kws">
          <div className="m-y-3 f-s-2 t-a-c f-w-600">Update Admin</div>
          <div className="row m-y-1">
            <div className="col-4 f-s-1_25 f-w-600">Name</div>
            <div className="col-8">
              <input
                type="text"
                className="w-100 b-1 b-r-5 pa-0_5"
                placeholder="enter name ...."
                value={name}
                onChange={(e) => setnames(e.target.value)}
              />
            </div>
          </div>
          <div className="row m-y-1">
            <div className="col-4 f-s-1_25 f-w-600">Email</div>
            <div className="col-8">
              <input
                type="email"
                className="w-100 b-1 b-r-5 pa-0_5"
                placeholder="enter email ...."
                value={email}
                onChange={(e) => setemails(e.target.value)}
              />
            </div>
          </div>
          <div className="row m-y-1">
            <div className="col-4 f-s-1_25 f-w-600">Username</div>
            <div className="col-8">
              <input
                type="text"
                className="w-100 b-1 b-r-5 pa-0_5"
                placeholder="enter username ...."
                value={username}
                onChange={(e) => setusernames(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-4 f-s-1_25 f-w-600">Password</div>
            <div className="col-8">
              <input
                type="password"
                className="w-100 b-1 b-r-5 pa-0_5"
                placeholder="enter password ...."
                value={password}
                onChange={(e) => setpasswords(e.target.value)}
              />
            </div>
          </div>
          <div className="row m-y-1">
            <div className="col-4 f-s-1_25 f-w-600">Confirm Password</div>
            <div className="col-8">
              <input
                type="password"
                className="w-100 b-1 b-r-5 pa-0_5"
                placeholder="enter confirm password ...."
                value={confirm_password}
                onChange={(e) => setconfirm_passwords(e.target.value)}
              />
            </div>
          </div>
          <div className=" t-a-c m-t-3">
            <button
              className="f-w-600 f-s-1_5 b-c-t c-w b-1 b-r-20 p-x-3 p-y-0_5"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>{" "}
      </form>
      <Toaster position="top-center" reverseOrder={true} />
    </div>
  );
}

export default Super_admin;
