import axios from "axios";
import React, { useEffect, useState } from "react";

function Image_api({ target_api_id }) {
  const [image, setImage] = useState();
  useEffect(() => {
    const fetchCryptData = async () => {
      try {
        const response = await axios.get(
          `https://pro-api.coingecko.com/api/v3/coins/${target_api_id}?x_cg_pro_api_key=CG-VTWbFPyR2KdGKX9LPTiPFBWW`,
        );
        console.log("eadfw", response.data.image.large);
        setImage(response.data.image.large);
        // setCryptoData(response.data.prices);
      } catch (error) {
        console.error("Error fetching crypto data:", error);
      }
    };
    fetchCryptData();
  }, [target_api_id]);

  return (
    <div>
      <img src={image} className="w-2  b-r-50" />
    </div>
  );
}

export default Image_api;
